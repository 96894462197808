<template>
<div class="boxcontent" style="margin-top:0px;">
    <div class="order_aside" style=" margin: 0px;">
    <div class="row">



    <div class="col-md-7 work_bxleft" style="height: 100vh; overflow-y: scroll;overflow-x:hidden;">
        <section id="salad_one" v-for="(salad,index) in this.menuitems">
            <header class="salad_one_top">
                <h2 :id="'heading'+index" tabindex="0" class="font-bold leading-normal" style="font-weight: 900;color:var(--headerText);">{{salad.portionname}}</h2>
                <p tabindex="0" class="menuinfo md:pl-4 md:text-right" style="margin-top: 20px; margin-bottom: 10px; color:var(--headerText);">Select up to {{salad.maxcapicity}} {{salad.portionname}}. Select again to add multiple scoops.</p> 
                            
            </header>   
            <div class="row">
            <div class="col-xs-4 col-sm-3 col-md-3 nopad text-center" v-for="items in salad.portionitems">
                <div class="list_bx" @click.prevent="menuitemclick(items,salad)">
                    <label :id="'menuid'+items.id" :class="`image-checkbox  ${items.active=='on' ? 'image-checkbox-checked' : ''}`"   >
                        <img class="img-responsive" :src="url+'images/menu_item_options/'+items.img">
                        <input type="checkbox" name="image[]" value="" />
                        <i class="fa fa-check hidden"></i>
                    </label>
                    <div class="text-center">
                        <p aria-hidden="true" class="productname mt-2" style="margin:0px; line-height: 1.5;">{{items.name}}</p><p  style="margin:0px; line-height: 1.5;color:var(--cardTextColor);">{{items.cal}} Cal</p>
                    </div>
                    </div>
                            </div>
                        </div>
                </section>   
            </div>
                
                <div class="col-md-5 ">
                    <div class="working_process" style="padding: 30px; background-color:var(--cartBackground); height: 100vh;">
                        <div class="custom_orderlist_head">
                            <a :href="url" ><i class="fa fa-arrow-left" style="font-size:13px;"></i> Back to Menu</a>
                            <h2 style="font-weight:800; text-transform:uppercase;">{{this.menutitle}}</h2>
                            <p style="color:var(--cardTextColor);"><span style="font-size: 17px;top: 2px;position: relative; margin-right: 2px;">${{this.amount}}.</span> {{this.calories}} Cal</p>
                        </div>
                        <ul style="padding:0px;max-height:250px;overflow-y:scroll;">
                            <li class="relative d-flex items-center space-x-2" v-for="bag in this.menuitemsbag"><img style="width: 24px;height: 24px;position: relative;border-radius: 100%;margin-right: 7px;top: 0px;" class="img-responsive" :src="'/images/menu_item_options/'+bag.img"> {{bag.name}}<a href="#" @click.prevent="removeitme(bag)" style="font-size: 17px; top: 6px; position: relative; margin-left: 5px;"><i class="fas fa-times-circle"></i></a></li>
                            
                        </ul>

                        <a href="#" @click.prevent="atddtocart()" class="ordernow" style="color: rgb(255, 255, 255);
                            display: block;
                            width: 50%;
                            background:var(--cardBackground);
                            text-align: center;
                            line-height: 40px;
                            border-radius: 30px;
                            font-size: 15px;
                            ">Add to Bag</a>


                            
                    </div>
            </div>
        </div>
            </div>
        </div>
</template>
<style type="text/css">
    .swal2-checkbox{
        display: none!important;
    }
    .productname{font-weight: 800;}
   .leading-normal{
    padding:10px;
   }
   .menuinfo{
    margin-top:20px;
   }
  @media (max-width: 950px){
   .col-xs-4 {
    width: 33.33333333%!important;
   }
   .leading-normal{
    padding:10px;
   }
   .menuinfo{
    padding: 15px;
   }
    #heading0 {
    margin-top: 80px;
   }
}
</style>

<script>
export default {
  data(){
    return{
      menuitems:[],
      menutitle:'',
      menuitemsbag:[],
      calories:0,
      amount:[],
      quantity:1,
      product:[],
    }

  },
  props:['id'],
  mounted(){
    axios.get('/getmeuitem/'+this.id).then(response=>{
        this.menuitems=response.data.menus;
        this.menutitle=response.data.menutitle;
        this.menuitemsbag=response.data.activemenu;
        this.calories=response.data.calories;
        this.amount=response.data.amount;
        this.product=response.data.product;

        console.log(this.amount);
    })
  },
  methods:{

    menuitemclick(items,salad){

            var menuitem = this.menuitemsbag.find(item => item.id === items.id);
            if(menuitem==undefined){
               var maxcapacity=this.menuitemsbag.filter(x => x.portion_id === items.portion_id).length;
               if(maxcapacity==salad.maxcapicity){
                  this.$swal.fire({
                      title: 'YOU’VE REACHED THE MAX.',
                      text: 'You can only select '+salad.maxcapicity+' '+salad.portionname+'.', 
                      icon: 'error',
                      confirmButtonText: 'Got it'
                    })
                 // this.$swal("You can add more than "+salad.maxcapicity+" items in this portion");
               }else{

                    $("#menuid"+items.id).addClass('image-checkbox-checked');
                    this.menuitemsbag.push(items);

                    this.calories=this.calories+parseInt(items.cal);
                    this.amount=this.amount+parseFloat(items.price);
               }

             }else{

             this.menuitemsbag = this.menuitemsbag.filter((e)=>e.id !== items.id );
             $("#menuid"+items.id).removeClass('image-checkbox-checked');

             this.calories=this.calories-parseInt(items.cal);
             this.amount=this.amount-parseFloat(items.price);
          }
    
     },
    removeitme(bag){
        this.menuitemsbag = this.menuitemsbag.filter((e)=>e.id !== bag.id );
        this.calories=this.calories-parseInt(bag.cal);
        this.amount=this.amount-parseFloat(bag.price);
        $("#menuid"+bag.id).removeClass('image-checkbox-checked');


    },
    removeProductFromCart(product){
          this.$store.dispatch('removeProductFromCart',product);

        },
        checkout(){
            window.location.replace(this.$store.state.url+'checkout'); 
         },
        clearCartItems(){
          this.$store.dispatch("clearCartItems");

        },
        minusbutton(product){
         
           this.$store.dispatch('removeProduct',{
             product:product,
             quantity:1
           });
            
         },
         plusbutton(product){
         
           this.$store.dispatch('addProduct',{
             product:product,
             quantity:1

            })            
         },
         atddtocart(){

         this.$store.dispatch('addProduct',{ 
         product:this.product,
         productoption:this.menuitemsbag,
         quantity:this.quantity,
         amount:this.amount,

         })

       }
  },
  computed:{
        cart(){
          return this.$store.state.cart;
        },
        cartItemCount(){
            return this.$store.getters.cartItemCount
        },
        totalprice(){
           return this.$store.getters.cartTotalPrice 
        },
        url(){
         return this.$store.state.url;
        }

    },
}
</script>