<template>
	<div class="FAQ_faqQuestionsWrapper__v6MxX MuiBox-root css-0">
	  <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">What is EZlunch ?<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">EZLunch delivers meals & groceries from your favorite restaurants and marketplaces at unbeatable prices. We are committed to making food delivery more affordable, convenient, and more environmentally friendly.
      </p>
      </div>
    </div>
</div>
</template>
<script>
import $ from 'jquery';
export default{
  mounted(){
    $('.ExpandBlock_expandBtnSquare__mcmRo').click(function(){
        if($(this).hasClass("ExpandBlock_expandOnClickRotate__V0ofh")==true){
            $(this).removeClass('ExpandBlock_expandOnClickRotate__V0ofh');
               if($(this).parent().parent().parent(".ExpandBlock_expandBlockWrapper__p6f5Q > .ExpandBlock_expandBlockContentWrapper__fx3Rk")){
                   $(this).parent().parent().siblings('.ExpandBlock_expandBlockContentWrapper__fx3Rk').removeClass('ExpandBlock_expanded__o57uc');
               }
       }else{
       	 $(this).addClass('ExpandBlock_expandOnClickRotate__V0ofh');
       	 $(this).parent().parent().siblings('.ExpandBlock_expandBlockContentWrapper__fx3Rk').addClass('ExpandBlock_expanded__o57uc');
       }
    });
  }

}
</script>