<template>
<loading v-model:active="isLoading"
                 :can-cancel="true"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage"/>
<div style="display:grid;place-items: center;">
 <form style="width:600px;" id="localAccountForm" method="POST" class="localAccount login" aria-label="Sign in with your email address" @submit.prevent="submitcontactform()" >
        <div class="entry">
          <div class="entry-item">
            <label for="name">
              Your Name
            </label>
            <input  id="name" type="text" 
              title="Please enter your Name" required v-model="username"  placeholder="Enter Your Name" >
          </div>
           <div class="entry-item">
            <label for="phone">
              Phone Number
            </label>
            <input  id="phone" type="text" required v-model="phonenumber"
              title="Enter your phone number"   placeholder="Enter your phone number" >
          </div>
          <div class="entry-item">
            <label for="subject">
             Subject
            </label>
            <input  id="subject" type="text" 
              title="Please enter subject" required v-model="subject"  placeholder="Please enter subject" >
          </div>
            <div class="entry-item">
              <div class="password-label">
                <label for="message">Message</label>  
              </div>
              <textarea required  id="message" style="width:550px;" rows="6" autocomplete="current-password" v-model="messages" placeholder="Message" ></textarea>
            </div>
            <div class="working"></div>
            <div class="buttons">
             <button id="next" type="submit" form="localAccountForm">Contact</button>
            </div>
        </div>
      </form>
</div>
</template>
<script>
    import { toast } from 'vue3-toastify';
    import 'vue3-toastify/dist/index.css';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/css/index.css';
    export default{
     setup() {
      const notify = () => {
      toast("your message has been sent successfully!", {
        autoClose: 100000,
        position:'top-center'
      }); // ToastOptions
      };
      return {
         notify
       }
     },
    components: {
            Loading
    },
     data(){
         return{
          username:'',
          phonenumber:'',
          subject:'',
          messages:'',
          isLoading: false,
          fullPage: true
         }
       },
       methods:{
        submitcontactform(){
          this.isLoading = true;
          axios.post('submitcontactform',{
            username:this.username,
            phonenumber:this.phonenumber,
            subject:this.subject,
            messages:this.messages,

          }).then(response=>{
             this.isLoading = false;  
             this.username='';
             this.phonenumber='';
             this.subject='';
             this.messages='';
             this.notify(); 




          })
        }


       }
    }
</script>
<style scoped>
        textarea{
            color: #fff;
            background-color: rgba(255, 255, 255, .4);
        }
        label {
            display: block;
            color: #fff;
            line-height: 20px;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 3.2px;
            text-transform: uppercase;
            margin-bottom: 8px;
        }

        input:focus,
        textarea:focus,
        select:focus {
            outline: none;
        }

        input {
            width: 100%;
            text-align: left;
            color: #fff;
            margin-top: 6px;
            margin-bottom: 20px;
            padding: 4px;
            padding-left: 19px;
            border: none;
            font-size: 13px;
            line-height: 16.2px;
            height: 56px;
            box-sizing: border-box;
            outline: none;
            background-color: rgba(255, 255, 255, .4);
        }

        input::placeholder,textarea::placeholder {
            text-transform: capitalize !important;
            color: currentColor;
            opacity: .60;
        }

        @media (max-width: 600px) {
            #app {
                position: unset;
                height: unset;
                padding: 24px 8px;
            }
        }

        #container {
            max-width: 600px;
            width: 100%;
        }

        #citizensLogo {
            display: flex;
            justify-content: center;
            margin-bottom: 36px;
        }

        #citizensLogo a {
            text-decoration: none;
        }

        #localAccountForm {
            width: 100%;
            box-sizing: border-box;
            padding: 0px 24px;
        }

        #localAccountForm>.divider {
            display: none;
        }

        #next,
        #signup button {
            width: 100%;
            height: 46px !important;
            padding: 10px 30px !important;
            border: none;
            border-radius: 6px !important;
            box-sizing: border-box;
            font-size: 20px;
            color: #fff;
            font-weight: 500;
            text-transform: uppercase;
            cursor: pointer;
        }

        #next {
            background-color: #bb0606 !important;
        }

        #signup {
            margin: 24px 24px 0px;
        }

        #signup button {
            background-color: #fff !important;
            color: #000 !important;
        }

        #email {
            text-transform: lowercase;
        }

        #forgotPassword {
            display: none;
        }

        .intro {
            display: none;
        }

        .rememberMe {
            display: none;
        }

        .create {
            display: none;
        }

        .error p {
            color: #bb0606;
            margin: 2px 0px;
        }

        .loader-spinner-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .loader-spinner {
            border: 4px solid #fff;
            border-radius: 50%;
            border-top: 4px solid #000;
            width: 24px;
            height: 24px;
            -webkit-animation: spin 2s linear infinite;
            /* Safari */
            animation: spin 2s linear infinite;
        }

        #password-reset {
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: 20px;
        }

        #password-reset a {
            color: #fff;
            text-transform: uppercase;
            font-size: 16;
            text-decoration: none;
        }

        #password-reset a:hover {
            text-decoration: underline;
        }

        @-webkit-keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
            }
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        /*Social Button Section */

        #api>div>.intro {
            display: block !important;
            padding: 0px 20px;
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 1.6px;
            line-height: 16.2px;
            color: rgba(255, 255, 255, .7);
            margin-bottom: 20px;
        }

        .divider {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, .3);
            height: 1px;
            margin: 0px 20px 30px;
            font-weight: 500;
        }

        .divider h2 {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #000;
            width: 50px;
            font-size: 20px;
            font-weight: 400;
            color: rgba(255, 255, 255, .3);
            text-align: center;
            text-transform: lowercase;
        }

        .options {
            display: flex;
            justify-content: space-between;
            padding: 12px 20px;
            margin: 0px 0px 20px;
            flex-direction: row-reverse;
        }

        .options>div {
            display: flex;
            align-items: center;
        }

        .options button {
            border: none;
            background-color: transparent;
            color: rgba(255, 255, 255, 0.8);
            cursor: pointer;
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 1.6px;
            line-height: 16.2px;
            height: 40px;
            box-sizing: border-box;
            outline: none;
            padding: 0px 0px 0px 0px;
            background-color: #FFFFFF;
            width: 60px;
            height: 60px;
            border-radius: 4px;
        }

        .signup-reset-group {
            margin: 48px 0px 24px;
        }

        @media (max-width: 400px) {
            .options button {
                font-size: 14px;
            }
        }
        #facebookLogo {
            display: none;
        }

        #googleLogo {
            display: none;
        }

        #appleLogo {
            display: none;
        }

        #signup, #signupDivider {
            display: none;
        }
    </style>
