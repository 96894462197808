<template>
<div class="BrandsNav_brandsNavLinksIconsWrapper__eYIE6">

  <a class="BrandsNav_brandsNavLink__pllEA" :title="menu.name" href="#" v-for="menu in this.categories">
            <span style="box-sizing:border-box;display:inline-block;overflow:hidden;width:60px;height:60px;background:none;opacity:1;border:0;margin:0;padding:0;position:relative">
                      <img :alt="menu.name"  :src="'/images/categoryimage/'+menu.imagename" decoding="async" data-nimg="fixed" class="BrandsNav_brandsNavLinkImg__mbsUG" style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%;object-fit:contain" />
           </span>
   </a>
</div>
</template>
<script>
	export default{
		   computed:{
        cart(){
          return this.$store.state.cart;
        },
        address(){
          return this.$store.getters.address;
        },
        username(){
          return this.$store.getters.username;
        },
        cartItemCount(){
            return this.$store.getters.cartItemCount
        },
        totalprice(){
           return this.$store.getters.cartTotalPrice 
        },
        url(){
         return this.$store.state.url;
        },
        deliverycharge(){
         return this.$store.getters.deliverycharge;
        },
        totaldistance(){
         return this.$store.getters.totaldistance;
        },
        address(){
         return this.$store.getters.address;
        },
        addstate(){
         return this.$store.getters.addstate;
        },
        addcity(){
         return this.$store.getters.addcity;
        },
        addpostalcode(){
          return this.$store.getters.addpostalcode;
        },
        adduserapt(){
          return this.$store.getters.adduserapt;
        }

    },
		data(){
         return {
         	categories:[],
         }
		},
	  mounted(){
	  	axios.get(this.url+'getmenu').then(response=>{
	  		this.categories=response.data;
	  		console.log(this.categories);


	  	})

	  }

	}
</script>