<template>
    <h3 style="text-align: center; padding:0px 15px 20px;margin-top:0px; background-color: var(--secondary);; font-size: 16px; border-bottom: 2px solid rgb(187 187 187);color:var(--button-color)!important;"><span onclick="closeMenu()" id="close" style="text-align:left;display: block;position: relative;top:10px;cursor:pointer;margin-bottom: 20px;"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.318192 0.318191C0.521926 0.114457 0.798249 6.71557e-08 1.08637 6.71557e-08C1.3745 6.71557e-08 1.65082 0.114457 1.85455 0.318191L15.6818 14.1454C15.8855 14.3492 16 14.6255 16 14.9136C16 15.2018 15.8855 15.4781 15.6818 15.6818C15.4781 15.8855 15.2018 16 14.9136 16C14.6255 16 14.3492 15.8855 14.1454 15.6818L0.318192 1.85455C0.114458 1.65082 1.15387e-06 1.3745 1.15387e-06 1.08637C1.15387e-06 0.798248 0.114458 0.521925 0.318192 0.318191Z" fill="currentColor"></path><path d="M15.6818 0.318191C15.8855 0.521925 16 0.798248 16 1.08637C16 1.3745 15.8855 1.65082 15.6818 1.85455L1.85455 15.6818C1.65082 15.8855 1.3745 16 1.08637 16C0.798248 16 0.521926 15.8855 0.318191 15.6818C0.114457 15.4781 -1.25864e-08 15.2017 0 14.9136C1.26022e-08 14.6255 0.114457 14.3492 0.318191 14.1454L14.1454 0.318191C14.3492 0.114457 14.6255 -1.25943e-08 14.9136 0C15.2018 1.25943e-08 15.4781 0.114457 15.6818 0.318191Z" fill="currentColor"></path></svg></span><svg style="position: relative;
    top:0px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1H3L3.4 3M3.4 3H19L15 11H5M3.4 3L5 11M5 11L2.707 13.293C2.077 13.923 2.523 15 3.414 15H15M15 15C14.4696 15 13.9609 15.2107 13.5858 15.5858C13.2107 15.9609 13 16.4696 13 17C13 17.5304 13.2107 18.0391 13.5858 18.4142C13.9609 18.7893 14.4696 19 15 19C15.5304 19 16.0391 18.7893 16.4142 18.4142C16.7893 18.0391 17 17.5304 17 17C17 16.4696 16.7893 15.9609 16.4142 15.5858C16.0391 15.2107 15.5304 15 15 15ZM7 17C7 17.5304 6.78929 18.0391 6.41421 18.4142C6.03914 18.7893 5.53043 19 5 19C4.46957 19 3.96086 18.7893 3.58579 18.4142C3.21071 18.0391 3 17.5304 3 17C3 16.4696 3.21071 15.9609 3.58579 15.5858C3.96086 15.2107 4.46957 15 5 15C5.53043 15 6.03914 15.2107 6.41421 15.5858C6.78929 15.9609 7 16.4696 7 17Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg> Delivery to {{this.address.address}}</h3>    
    <div class="product_bag" v-for="(i,index) in this.cart.length">
              <div class="row">
                  <span v-if="this.cart.length && cart[index].name!=''>0" style="padding: 15px 8px;width: 100%;display: block;color: var(--cardTextColor);font-size: 16px;font-weight: 700;">Name: {{cart[index].name}}</span>
                <div class="col-md-8">
                    <h4 style="color:var(--cardTextColor);"  v-if="this.cart.length>0" >{{this.cart[index].product.name}} </h4>
                    <span><i class="fa fa-caret-down" style="top: 3px;color: rgb(255, 255, 255);position: relative; line-height: 30px;font-size: 27px;left: 10px;"></i></span>
                    <p style="color:var(--cardTextColor); font-size:14px; line-height: 16px;" v-if="this.cart.length>0">
                    <span v-for="item in this.cart[index].productoption">{{item.name}},</span>
                    </p>
                    <p style="color:var(--cardTextColor); font-size:14px; line-height: 16px;" v-if="this.cart.length>0">
                      <span style="font-weight: 700;">Dates:</span>
                      <span v-for="date in this.cart[index].dates">{{date}},</span>
                    </p>
                 </div>
                <div class="col-md-4">
                  <div class="items-center" style="display:flex;">
<!--                     <select  @change="quantity($event,this.cart[index].product,this.cart[index].productoption)" style="padding:5px;line-height:31px !important;height:31px;margin: 0px;position:relative;width:60%;">
                      <option :selected="i==this.cart[index].quantity?true : false" v-for="i in 10" :value="i" >{{i}}</option>
                    </select> -->
                    <input class="form-control" type="number"  :value="this.cart[index].quantity" style="height:35px;width:80px;" disabled>
                    <h4 style="color:var(--cardTextColor);line-height:28px;margin-left: 15px;padding: 0px;margin-top: 0px;margin-bottom: 0px; font-size: 16px;width:40%;">${{this.cart[index].amount}}</h4>
                  </div>
                </div>
              </div>
              <div class="row" style="margin:10px 0px;">
                <div class="col-md-12" style="padding: 0px !important;">
<!--                   <div class="col-md-12" style="display:inline-flex;">
                    <h4 style="color:var(--cardTextColor); font-size: 13px;margin-right: 7px;">Add Side Pita Free</h4>
                    <label class="switch">
                      <input type="checkbox">
                      <span class="slider round"></span>
                    </label>
                  </div> -->
                  <div class="col-md-12">
                    <ul style="padding: 0px; display: inline-flex; margin-bottom: 0px; margin-top:5px;">
                      <!-- v-if="this.cart[index].product.options.length>0" -->
                      <li  style="padding: 0px; margin-right: 10px; list-style: none;font-size: 15px;"><a style="color:var(--cardTextColor);" :href="url+'customize/'+this.cart[index].product.id">Modify</a></li>
                      <li style="padding: 0px; margin-right: 10px; list-style: none;    font-size: 15px;"><a style="color:var(--cardTextColor);" href="#" @click="renameuserinfo(this.cart[index].product)" data-toggle="modal" data-target="#Rename">Rename</a></li>
                      <li style="padding: 0px; margin-right: 10px; list-style: none;    font-size: 15px;"><a style="color:var(--cardTextColor);" data-toggle="modal" data-target="#Remove" href="#" @click.prevent="updateproductitem(this.cart[index].product)" >Remove</a></li>
                      <li style="padding: 0px; margin-right: 10px; list-style: none;    font-size: 15px;"><a style="color:var(--cardTextColor);"  href="#" @click="openmodal(this.cart[index].product)">Add Date</a></li>
                      <!--  -->
                    </ul>
                  </div>
                  <div class="bdr"></div>
                </div>

              </div>
            </div>
        
            <div class="row product_bag_footer">
              <div class="col-md-12" style="display:flex;padding:0px 20px;">
                <div class="col-md-6" style="width:50%">
                  <h3 style="color:var(--cardTextColor); font-size: 16px; font-weight: bold; margin: 0px;line-height: 28px;">Bag Total</h3>
                </div>
                <div class="col-md-6" style="width:50%">
                  <span style="color:var(--cardTextColor); text-align:right;display:block;font-size:15px;">${{this.totalprice}}</span>
                </div>
              </div>
              <div  style="width:100%;margin: 20px 0px;display:inline-flex;justify-content: center;">
                <div style="width:40%">
                  <a :href="url" class="ordernow" style="background: var(--secondary);text-align: center;padding: 15px 26px;;line-height: 40px;border-radius: 30px;color:var(--cardTextColor);font-size: 15px;">Add More Items</a>
                </div>
                <div style="width:40%">
                  <!-- <a :href="url+'checkout'" class="ordernow" style="color: #fff;width: 50%;background-color: var(--secondary);text-align: center;    padding: 15px 50px;line-height: 40px;border-radius: 30px;    font-size: 15px;">Checkout</a> -->
                 <a @click="checkoutclick();" class="ordernow" href="#" style="color: #fff;width: 50%;background-color: var(--secondary);text-align: center;    padding: 15px 50px;line-height: 40px;border-radius: 30px;    font-size: 15px;">Checkout</a> 
                </div>
              </div>
            </div>
            <!-- Modal -->
              <!-- Trigger the modal with a button -->
              
              <!-- Modal -->
              <div class="modal fade" id="Rename" role="dialog">
                <div class="modal-dialog">
                
                  <!-- Modal content-->
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal">&times;</button>
                      <h4 class="modal-title">GIVE THIS MEAL A NAME</h4>
                    </div>
                    <div class="modal-body">
                      <form>
                        <div class="form-group">
                          <label for="usr">Add Name:</label>
                          <input v-model="renameuser" type="text" class="form-control" id="usr">
                        </div>
                        
                      </form>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default" data-dismiss="modal" style="padding: 10px; border: 1px solid var(--cardTextColor);border-radius: 30px;width: 150px; font-size: 16px;">Cancel</button>
                      <button @click.prevent="savename()" type="button" class="btn btn-default" style="padding: 10px; border: 1px solid var(--cardTextColor);border-radius: 30px;width: 150px; font-size: 16px;background: rgb(255, 248, 232);">Save</button>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div class="modal fade" id="Remove" role="dialog">
                <div class="modal-dialog">
                
                  <!-- Modal content-->
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal">&times;</button>
                      <h4 class="modal-title">REMOVE YOUR MEAL?</h4>
                    </div>
                    <div class="modal-body">
                      <p>Are you sure you would like to remove {{this.productitem.name}} from your bag?</p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default" data-dismiss="modal" style="padding: 10px; border: 1px solid var(--cardTextColor);border-radius: 30px;width: 150px; font-size: 16px;">Cancel</button>
                      <button @click.prevent="removeProductFromCart()" type="button" class="btn btn-default" style="padding: 10px; border: 1px solid var(--cardTextColor);border-radius: 30px;background: rgb(255 187 33);padding: 10px 30px; font-size: 16px;">Confirm Removal</button>
                    </div>
                  </div>
                  
                </div>
              </div>

             <transition name="slide-fade">
              <div class="modal-mask2" v-if="show2">
                <div class="modal-wrapper2">
                  <div class="modal-container2">
                    <div class="modal-header">
                      <span @click="show2 = false" id="close" style="text-align: left; display: block; position: relative; top: 10px; cursor: pointer; margin-bottom: 20px;"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.318192 0.318191C0.521926 0.114457 0.798249 6.71557e-08 1.08637 6.71557e-08C1.3745 6.71557e-08 1.65082 0.114457 1.85455 0.318191L15.6818 14.1454C15.8855 14.3492 16 14.6255 16 14.9136C16 15.2018 15.8855 15.4781 15.6818 15.6818C15.4781 15.8855 15.2018 16 14.9136 16C14.6255 16 14.3492 15.8855 14.1454 15.6818L0.318192 1.85455C0.114458 1.65082 1.15387e-06 1.3745 1.15387e-06 1.08637C1.15387e-06 0.798248 0.114458 0.521925 0.318192 0.318191Z" fill="currentColor"></path><path d="M15.6818 0.318191C15.8855 0.521925 16 0.798248 16 1.08637C16 1.3745 15.8855 1.65082 15.6818 1.85455L1.85455 15.6818C1.65082 15.8855 1.3745 16 1.08637 16C0.798248 16 0.521926 15.8855 0.318191 15.6818C0.114457 15.4781 -1.25864e-08 15.2017 0 14.9136C1.26022e-08 14.6255 0.114457 14.3492 0.318191 14.1454L14.1454 0.318191C14.3492 0.114457 14.6255 -1.25943e-08 14.9136 0C15.2018 1.25943e-08 15.4781 0.114457 15.6818 0.318191Z" fill="currentColor"></path></svg></span>
                      <slot name="header" style="text-align: center;display: block;font-size: 25px;font-weight: bold;">
                        Add pre-order date for this item
                      </slot>
                    </div>
                    <div class="modal-body">
                         <div>
                          <div v-for="month in months" :key="month" class="mycladnder">
                            <h3 style="color:black">{{ formatMonthName(month) }}</h3>
                              <div class="calendar">
                                <div
                                  v-for="date in getDaysInMonth(month)"
                                  :key="date"
                                  :class="{ selected: isSelected(date, month), disabled: isDisabled(date, month) }"
                                  @click="selectDate(date, month)"
                                >
                                  {{ date }}
                                </div>
                              </div>
                          </div>
                        </div>
                        <div>
                            <h4 style="color:black">Selected Dates:</h4>
                            <ul>
                              <li v-for="(date, index) in selectedDates" :key="index">{{ date }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer">
                      <slot name="footer">
                        <button class="modal-default-button" @click="addtodaymenu()">
                          Add
                        </button>
                      </slot>
                    </div>
                  </div>
                </div>
              </div>  
             </transition>

            <transition name="slide-fade">
            <div class="modal-mask3" v-if="show3">
              <div class="modal-wrapper3">
                <div class="modal-container3">
                  <div class="modal-header">

                    <span @click="show3 = false" id="close" style="text-align: left; display: block; position: relative; top: 10px; cursor: pointer; margin-bottom: 20px;"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.318192 0.318191C0.521926 0.114457 0.798249 6.71557e-08 1.08637 6.71557e-08C1.3745 6.71557e-08 1.65082 0.114457 1.85455 0.318191L15.6818 14.1454C15.8855 14.3492 16 14.6255 16 14.9136C16 15.2018 15.8855 15.4781 15.6818 15.6818C15.4781 15.8855 15.2018 16 14.9136 16C14.6255 16 14.3492 15.8855 14.1454 15.6818L0.318192 1.85455C0.114458 1.65082 1.15387e-06 1.3745 1.15387e-06 1.08637C1.15387e-06 0.798248 0.114458 0.521925 0.318192 0.318191Z" fill="currentColor"></path><path d="M15.6818 0.318191C15.8855 0.521925 16 0.798248 16 1.08637C16 1.3745 15.8855 1.65082 15.6818 1.85455L1.85455 15.6818C1.65082 15.8855 1.3745 16 1.08637 16C0.798248 16 0.521926 15.8855 0.318191 15.6818C0.114457 15.4781 -1.25864e-08 15.2017 0 14.9136C1.26022e-08 14.6255 0.114457 14.3492 0.318191 14.1454L14.1454 0.318191C14.3492 0.114457 14.6255 -1.25943e-08 14.9136 0C15.2018 1.25943e-08 15.4781 0.114457 15.6818 0.318191Z" fill="currentColor"></path></svg></span>
                    <slot name="header" style="text-align: center;display: block;font-size: 25px;font-weight: bold;">
                      Add Product Quantity
                    </slot>
                  </div>
                  <div class="modal-body">
                      <select class="form-control" style="height:50px;" @change="itemquantity($event)">
                         <option>Select Quantity</option>
                         <option v-for="i in 15" :value="i">{{i}}</option>
                      </select>  
                  </div>
                  <div class="modal-footer">
                    <slot name="footer">
                      <button class="modal-default-button" @click="addtomenu()">
                        Add
                      </button>
                    </slot>
                  </div>
                </div>
              </div>
            </div>  
           </transition>
</template>
<style>
  .preordertime{
    width: 15px;
    height: 15px;
    margin: 0px 15px;
  }
  #menu-container{
    width: 530px;
    right: -530px;
    background: var(--headerBackground);
    height: 100vh;
    position: fixed;
    z-index: var(--zIndexCartDrawer);
    top:0;
    transition: all 0.5s ease-in-out;
    overflow: scroll;
    overflow-x: hidden;
    box-shadow: -9px 2px 5px -8px rgba(0,0,0,0.75);
-webkit-box-shadow: -9px 2px 5px -8px rgba(0,0,0,0.75);
-moz-box-shadow: -9px 2px 5px -8px rgba(0,0,0,0.75);
   }
@media (max-width: 650px){
.modal-container2,.modal-container3{
  width:95%!important;
  margin: 5% auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  transform: translateY(-100%);
  transition: transform .3s ease-out;
  max-height: 600px;
  overflow-y: scroll;
}

.mycladnder {
    float: none!important;
    width: 50%;
}


}

@media (max-width: 950px){
   #menu-container {
    background: var(--headerBackground);
    box-shadow: -9px 2px 5px -8px rgba(0,0,0,.75);
    -webkit-box-shadow: -9px 2px 5px -8px rgba(0,0,0,.75);
    -moz-box-shadow: -9px 2px 5px -8px rgba(0,0,0,.75);
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
    position: fixed;
    right: -340px;
    top: 0;
    transition: all .5s ease-in-out;
    width: 340px;
    z-index: var(--zIndexCartDrawer);
}
.ordernow{
  padding:10px 15px!important;
  cursor: pointer;
}

.modal-container2,.modal-container3{
  width:95%;
  margin: 5% auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  transform: translateY(-100%);
  transition: transform .3s ease-out;
}

}
.switch {
  position: relative;
  display: inline-block;
  width: 50px !important;
  height: 25px !important;
  margin-top: 5px !important;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.bdr {
    height: 2px;
    width: 96%;
    background: #dbdbdb;
    text-align: center;
    margin: 7px 13px;
    display: inline-block;
}
.bdr2 {
    height: 2px;
    width: 100%;
    background: #ddd;
}
.product_bag_footer{
  padding: 30px 0px;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background-color: var(--cardTextColor);
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background: #333333a3;
}
.modal-content {
   
    padding: 30px;
}
.modal-header .close {
    padding: 1rem;
    margin: 0px;
    font-size: 29px;
}
.product_bag{
  padding: 0px 15px;
}
</style>
<style>
.mycladnder{
  float:left;
  width:50%;
}
.calendar {
  display: flex;
  flex-wrap: wrap;
  width: 250px;
}

.modal-body ul li{
  
   position: relative;
    display: inline-block;
    padding: .5em .75em;
    margin-bottom: .1rem;
    font-size: 75%;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    background-color: #007bff;
    margin-left: 25px;

}
.modal-body ul li::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 10px solid #007bff;
}
.modal-body ul li:hover {
    color: #fff;
    background-color: #0056b3;
    cursor: pointer;
}

.modal-body ul li:hover::before {
    border-right: 10px solid #0056b3;
}

.calendar div {
  border: 1px solid #ccc;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: 1px;
  cursor: pointer;
}

.calendar .selected {
  background-color: #00f;
  color: #fff;
}

.calendar .disabled {
  color: red;
  cursor: not-allowed;
}

.modal-container2{
  width:60%;
  margin: 5% auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  transform: translateY(-100%);
  transition: transform .3s ease-out;
}
.modal-container3{
  width:60%;
  margin: 5% auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  transform: translateY(-100%);
  transition: transform .3s ease-out;
}
.modal-header {
    padding: 5px!important;
    border-bottom: 1px solid #e5e5e5;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}
.modal-mask2{
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}
.modal-mask3{
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}
</style>
<script>
import $ from 'jquery';
import { ref,watch,nextTick} from "vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Swal from 'sweetalert2';
export default{
   setup() {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const nextMonth = (currentMonth % 12) + 1;
    const months = ref([currentMonth, nextMonth]);
    const selectedDates = ref([]);
    const lastselectedmonth=ref('');
    const show3 = ref(false);
    const currentYear = today.getFullYear();
    const notify = () => {
      toast("Date added successfully", {
        autoClose: 100000,
        position:'top-center'
      }); // ToastOptions
    };

    const getDaysInMonth = (month) => {
      return new Date(currentYear, month, 0).getDate();
    };

    const formatMonthName = (month) => {
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return monthNames[month - 1];
    };
    const isSelected = (date, month) => {
      return selectedDates.value.includes(`${formatMonthName(month)} ${date}, ${currentYear}`);
   
    };

    const isDisabled = (date, month) => {
      if (month === currentMonth && date < today.getDate()) {
        return true;
      }
      return false;
    };

    const selectDate = (date, month) => {

      show3.value=true;

      if (isDisabled(date, month)) return;
      const dateString = `${formatMonthName(month)} ${date}, ${currentYear}`;
      lastselectedmonth.value=dateString;
      if (isSelected(date, month)) {
        selectedDates.value = selectedDates.value.filter((d) => d !== dateString);
      } else {
        
      }
    };
    watch(show3, (newValue) => {
          if (newValue) {
             $('.modal-container3').css('transform','translateY(0)');
           } else {
            $('.modal-container3').css('transform','translateY(-100%)');
          
          }

    });

    return {
      months,
      getDaysInMonth,
      formatMonthName,
      isSelected,
      isDisabled,
      selectDate,
      selectedDates,
      lastselectedmonth,
      notify,
      show3,
    };
  },
  data(){
    return {
        productitem:[],
        renameuser:'',
        useraddress:'',
        show2:false,
        selectedproduct:[],
        selectedOption:'',
        itemqty:0,
        show2:false,
     }
   },
   mounted(){

        axios.get('getaddress').then(response=>{
        this.useraddress=response.data.myaddress[0].address;
        this.$store.dispatch('Addaddress',{
             address:this.useraddress});
         
      })

   },
    watch: {
        show2(newVal) {
         this.$nextTick(() => {
          if (newVal) {
          document.querySelector('.modal-container2').style.transform = 'translateY(0)';
           } else {
          document.querySelector('.modal-container2').style.transform = 'translateY(-100%)';
          }
         });
        },
        show3(newVal) {
         this.$nextTick(() => {
          if (newVal) {
          document.querySelector('.modal-container3').style.transform = 'translateY(0)';
           } else {
          document.querySelector('.modal-container3').style.transform = 'translateY(-100%)';
          }
         });
        },
        selectedOption(newValue) {
           console.log('Selected option changed:', newValue);
        }
    },
   computed:{
        cart(){
          return this.$store.state.cart;
        },
        address(){
          return this.$store.getters.address;
        },
        username(){
          return this.$store.getters.username;
        },
        cartItemCount(){
            return this.$store.getters.cartItemCount
        },
        totalprice(){
           return this.$store.getters.cartTotalPrice;
        },
        url(){
         return this.$store.state.url;
        },
         currentMonthDays() {
      const firstDayOfMonth = new Date(this.currentMonth).getDay();
      const lastDayOfMonth = new Date(
        new Date(this.currentMonth).getFullYear(),
        new Date(this.currentMonth).getMonth() + 1,
        0
      ).getDate();
      return Array.from({ length: lastDayOfMonth }, (_, index) => index + 1).slice(firstDayOfMonth);
    },
    nextMonthDays() {
      const firstDayOfMonth = new Date(this.nextMonth).getDay();
      const lastDayOfMonth = new Date(
        new Date(this.nextMonth).getFullYear(),
        new Date(this.nextMonth).getMonth() + 1,
        0
      ).getDate();
      return Array.from({ length: lastDayOfMonth }, (_, index) => index + 1).slice(0, 7 - firstDayOfMonth);
    },

    },
    methods:{
      itemquantity(event){
        this.itemqty=event.target.value;
      },
      checkoutclick(){
        if(this.cart.length==0){
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Your cart is empty',
            showConfirmButton: false,
            timer: 1500
           });
        }else{

          var count=0;
          this.cart.forEach(item=>{
              if(item.dates.length==0){
                 count=1;
              }
          });

          if(count==1){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Some of your items do  not have a preorder date',
                showConfirmButton: false,
                timer: 1500
             });

          }else{

            
             window.location.replace(this.url+"checkout");



          }



        }
        
      },
      addtodaymenu(){
        this.$store.dispatch('SaveDate',{
             product:this.selectedproduct,
             dates:this.selectedDates
        });
        this.selectedDates=[];
        //this.notify();
        this.show2=false;
      },
      addtomenu(){

        var menuamount=this.lastselectedmonth+'  Quantity:'+this.itemqty;
        this.selectedDates.push(menuamount);
        this.show3=false;
        // this.$store.dispatch('SaveDate',{
        //      product:this.selectedproduct,
        //      dates:this.selectedDates
        // });
        // this.selectedDates=[];
        this.notify();
        //this.show2=false;
      },
      openmodal(product){
        this.selectedproduct=product;
        console.log(this.selectedproduct);
        this.show2=true;
      },

      formatDate(dateString) {
      const date = new Date(dateString)
      return date.toDateString()
      },
        renameuserinfo(cartitemnumber){
          
           this.selectedproduct=cartitemnumber;
           console.log(this.selectedproduct);
        },
        savename(){

          this.$store.dispatch('SaveName',{
             product:this.selectedproduct,
             username:this.renameuser});
          $('#Rename').modal('hide');

        },

         updateproductitem(productitem){

            this.productitem=productitem;

         },
         removeProductFromCart(){

          this.$store.dispatch('removeProductFromCart',this.productitem);
          $('#Remove').modal('hide');
          


        },
        quantity(event,product,amounts){

             let productoption=[];
             var selectElement = event.target.value;
             this.$store.dispatch('addProduct',{
             product:product,
             quantity:selectElement,
             amount:product.unit_price,
             productoption:productoption,

             }) 

        }
    }

}
</script>