import './bootstrap';
import { createApp } from 'vue';
import { createStore } from 'vuex'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Welcome from './components/Welcome';
import Cartitem from './components/Cartitem';
import Cartbar from './components/Cartbar';
import Ordersummary from './components/Ordersummary';
import Deliverydetail from './components/Deliverydetail';
import Showproduct from './components/Showproduct';
import Viewcategoryitme from './components/Viewcategoryitme';
import Listitems from './components/Listitems';
import Googlemap from './components/Googlemap';
import Checkout from './components/Checkout';
import Sidenavigation from './components/Sidenavigation';
import Termscondition from './components/Termscondition';
import Privecy from './components/Privecy';
import Aboutus from './components/Aboutus';
import Faq from './components/Faq';
import Driverfaq from './components/Driverfaq';
import Contact from './components/Contact';


import StoreData from './store';
const app = createApp({});
const store = createStore(StoreData);
app.use(store);
app.use(VueSweetalert2);
app.component('welcome', Welcome)
app.component('cartitem', Cartitem)
app.component('cartbar', Cartbar)
app.component('ordersummary', Ordersummary)
app.component('deliverydetail', Deliverydetail)
app.component('showproduct', Showproduct)
app.component('viewcategoryitme', Viewcategoryitme)
app.component('listitems', Listitems)
app.component('googlemap', Googlemap)
app.component('checkout', Checkout)
app.component('sidenavigation', Sidenavigation)
app.component('termscondition', Termscondition)
app.component('privecy', Privecy);
app.component('aboutus', Aboutus);
app.component('faq', Faq);
app.component('driverfaq', Driverfaq);
app.component('contact', Contact);
app.mount('.app')
