<template>
	<div class="FAQ_faqQuestionsWrapper__v6MxX MuiBox-root css-0">

	  <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">Do have any query?
Let FAQ's help you<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">This is not on-demand delivery. Pre-scheduled and optimize routes With EZLunch, routes are pre-scheduled and optimized ahead of time for efficiency and reduced environmental impact.
      </p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">Do I have to pay to be a member?<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">No! It is completely free to sign up for EZLunch.
</p>
      </div>
    </div>

    <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">When is the latest time I can place an order?<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">For lunch you have until 12 am the night before to place your orders. For dinner, you have until 2:30 PM day of delivery to place your orders. You can also schedule your orders up to 7 days in advance.
      </p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">When do you deliver?<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">We deliver every single day of the week. You can choose from a variety of 45-minute delivery windows between 11:30 am and 2:00 pm for lunch and 5:30 pm to 8:00 pm for dinner.
</p>
      </div>
    </div>
	  <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">Can I choose different dishes?<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">Of course! You have the freedom to choose any dish or groceries from a wide variety of restaurant and market place options that EZLunch offers. Even once you’ve placed your order, you can still select different dishes or groceries within the same order even is the dishes or groceries are from a different restaurant or grocery. By doing this, we are able to keep costs low for you as well as optimizing our delivery routes to get your dish or grocery to you as quickly and with as little environmental impact as possible.</p>
      </div>
    </div>

</div>
</template>
<script>
import $ from 'jquery';
export default{
  mounted(){
    $('.ExpandBlock_expandBtnSquare__mcmRo').click(function(){
        if($(this).hasClass("ExpandBlock_expandOnClickRotate__V0ofh")==true){
            $(this).removeClass('ExpandBlock_expandOnClickRotate__V0ofh');
               if($(this).parent().parent().parent(".ExpandBlock_expandBlockWrapper__p6f5Q > .ExpandBlock_expandBlockContentWrapper__fx3Rk")){
                   $(this).parent().parent().siblings('.ExpandBlock_expandBlockContentWrapper__fx3Rk').removeClass('ExpandBlock_expanded__o57uc');
               }
       }else{
       	 $(this).addClass('ExpandBlock_expandOnClickRotate__V0ofh');
       	 $(this).parent().parent().siblings('.ExpandBlock_expandBlockContentWrapper__fx3Rk').addClass('ExpandBlock_expanded__o57uc');
       }
    });
  }

}
</script>