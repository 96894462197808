<template>
	<div class="FAQ_faqQuestionsWrapper__v6MxX MuiBox-root css-0">

	  <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">Who we are?<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">the pre-schedule, restaurant delivery platform bringing quality meals to consumers at a great value

Whether a pre-planned lunch delivered to the office, a stress-free family dinner, or a weekday meal between calls, subscribers love the quality of food, convenience and savings they realize by ordering ahead with EZLunch. Through EZLunch, customers and their families explore and enjoy new gastronomies every day without breaking the bank or spending any time cooking. By allowing restaurants to better utilize their kitchens during their off-peak hours, EZLunch helps restaurant partners increase their profitability, especially as restaurants start to reopen and indoor dining expands. COVID-19 highlighted pain points in the restaurant business model, which needs solutions, like EZLunch, that allow for better efficiency and greater sales. On the delivery front, EZLunch's optimized in-house delivery operations platform allows drivers to deliver more orders per hour than on-demand models, thus reducing the environmental impact and cost of food delivery. With hundreds of restaurant partn
</p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">What they say<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">DIEGO ENRRIQUE</p>
        <p class="ExpandBlock_expandBlockContent__N5vhS">One of the best experiences for your meals at the office. They do a great job offering you options for you menus at the office and delivering the meals, you don't have to worry about anything, just place the orders and they take care of it! And they have a lot of options to choose from, you can't ask for more.</p>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">Dylan</p>
        <p class="ExpandBlock_expandBlockContent__N5vhS">Best Catering Company Around! The prices are the best, unbeatable and customer service along with delivery is always great and on time. Everyone in the office is always satisfied and happy with our meals, and we love being able to choose from such a great variety. Between the affordable rate, great service, and food quality we will always come back to Club Feast.</p>
      </div>

      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">José Antonio Cárdenas</p>
        <p class="ExpandBlock_expandBlockContent__N5vhS">Good Price, Even Better Service My experience with club feast has been the best I've had with a catering service. First, we start with the way they treated me from the beginning, always polite and making me feel important and valued. Then the food is super cheap, tasty, and always delivered on time.</p>
      </div>

      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">Yulissa Sanchez</p>
        <p class="ExpandBlock_expandBlockContent__N5vhS">The food is great , the delivery is on time the price is right and the service great …</p>
      </div>


    </div>

</div>
</template>
<script>
import $ from 'jquery';
export default{
  mounted(){
    $('.ExpandBlock_expandBtnSquare__mcmRo').click(function(){
        if($(this).hasClass("ExpandBlock_expandOnClickRotate__V0ofh")==true){
            $(this).removeClass('ExpandBlock_expandOnClickRotate__V0ofh');
               if($(this).parent().parent().parent(".ExpandBlock_expandBlockWrapper__p6f5Q > .ExpandBlock_expandBlockContentWrapper__fx3Rk")){
                   $(this).parent().parent().siblings('.ExpandBlock_expandBlockContentWrapper__fx3Rk').removeClass('ExpandBlock_expanded__o57uc');
               }
       }else{
       	 $(this).addClass('ExpandBlock_expandOnClickRotate__V0ofh');
       	 $(this).parent().parent().siblings('.ExpandBlock_expandBlockContentWrapper__fx3Rk').addClass('ExpandBlock_expanded__o57uc');
       }
    });
  }

}
</script>