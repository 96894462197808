import createPersistedState from "vuex-persistedstate";
export default {
  state (){
    return {
      cart:[],
      url:"https://ai.enxalata.com/",
      //https://ezlunchonline.com/
      // http://localhost:8000/
      username:'',
      maintotal:0,
      address:'',
      deliverycharge:0,
      totaldistance:0,
      city:'',
      newstate:'',
      postalcode:'',
      userapt:'',
      lat:'',
      lan:'',
      totalday:1,
    }
    // User: aicodingx_ezorder

    // Database: aicodingx_ezorder
    // p3-8q74FKS[O
 },
 getters: {
    cartItemCount(state) {
            return state.cart.length;
    },
    cartTotalPrice(state){
            let total =0;
            state.cart.forEach(item=>{
               total =total+parseFloat(item.amount); 
            })

            let numToTwoDecimals = total.toFixed(2);

            return numToTwoDecimals;
    },
    username(state){

       return state.username; 

    },
    address(state){
       return state.address; 
    },
    addstate(state){
       return state.newstate; 
    },
    addcity(state){
       return state.city; 
    },
    adduserapt(state){
      return state.userapt; 
    },
    addpostalcode(state){
       return state.postalcode; 
    },
    deliverycharge(state){
       return state.deliverycharge; 
    },
    totaldistance(state){
       return state.totaldistance; 
    },
    lat(state){
       return state.lat; 
    },
    lan(state){
       return state.lan; 
    },
 },
  mutations:{
        ADD_TOCART(state,{product,productoption,quantity,amount}){
            let productInCart=state.cart.find(item=>{
                return item.product.id===product.id; 
            });
            
            if(productInCart){
                productInCart.quantity =quantity;
                productInCart.amount =parseFloat(amount)*parseInt(quantity);
                return;
            }
            var name='';
            let dates=[];
            state.cart.push({
                product,
                productoption,
                quantity,
                amount,
                name,
                dates
                

            })

        },
        REMOVE_TOCART(state,{product,quantity}){
            let productInCart=state.cart.find(item=>{
                return item.product.id===product.id; 
            });
            if(productInCart){
                productInCart.quantity -=quantity;
                return;
            }
            state.cart.pop({
                product,
                quantity
            })

        },
        REMOVE_PRODUCT_FROM_CART(state,product){
            state.cart=state.cart.filter(item=>{
                return item.product.id !==product.id;
            })
        },
        CLEAR_CART_ITEMS(state){
           state.cart=[];
           state.deliverycharge=0;
           state.totaldistance=0;
           state.totalday=1;
        },
        CLEAR_SHIPPINGCHARGE(state){
         state.deliverycharge=0;
         state.totaldistance=0;
         state.state.totalday=1;

        },

        ADD_USER(state,{product,username}){
               let productInCart=state.cart.find(item=>{
                return item.product.id===product.id; 
            });
            if(productInCart){
                productInCart.name =username;
            }
           
        },
        ADD_DATE(state,{product,dates}){
            var totalquantity=0;
            dates.find(item=>{
               const match = item.match(/Quantity:(\d+)/);
               let quantity;
               if (match) {
                  quantity = parseInt(match[1], 10);
                  totalquantity=totalquantity+quantity;
               }
            });
               let productInCart=state.cart.find(item=>{
                return item.product.id===product.id; 
            });
            if(productInCart){
                productInCart.dates=dates;
                productInCart.quantity =totalquantity;
                productInCart.amount =parseFloat(productInCart.amount)*parseInt(totalquantity);
            }
           
        },

        ADD_ADDRESS(state,address){
           state.address=address;
        },
        ADD_CITY(state,city){
           state.city=city;
        },
        ADD_STATE(state,newstate){
           state.newstate=newstate;
        },
        ADD_POSTALCODE(state,postalcode){
           state.postalcode=postalcode;
        },
        ADD_APT(state,userapt){
           state.userapt=userapt;
        },
        ADD_DELIVERYCHARGE(state,deliverycharge){
           state.deliverycharge=parseFloat(deliverycharge.deliverycharge);
        },
        UPDATE_DATE(state,updatedate){
           state.totalday=updatedate.updatedate;
        },
        ADD_LAT(state,lat){
           state.lat=lat.lat;
        },
        ADD_LAN(state,lan){
           state.lan=lan.lan;
        },
        ADD_TOTALDISTANCE(state,totaldistance){
             state.totaldistance=parseFloat(totaldistance.totaldistance);
        },
        UPDATE_MAINTOTAL(state,delveryamount){

           state.maintotal=parseFloat(delveryamount.delveryamount);
        },
        
      },
      actions: {

        SaveName:({ commit },{product,username}) =>{

            commit('ADD_USER', {product,username});

         },        
         SaveDate:({ commit },{product,dates}) =>{

            commit('ADD_DATE', {product,dates});

         },
         Addaddress:({ commit }, {address}) =>{

            commit('ADD_ADDRESS', {address});

         },
         Addcity:({ commit }, {city}) =>{

            commit('ADD_CITY', {city});

         },
         Addstate:({ commit }, {newstate}) =>{

            commit('ADD_STATE', {newstate});

         },
         Addpostalcode:({ commit }, {postalcode}) =>{

            commit('ADD_POSTALCODE', {postalcode});

         },
         Addapt:({ commit }, {userapt}) =>{

            commit('ADD_APT', {userapt});

         },
         deliverycharge:({ commit }, {deliverycharge}) =>{

            commit('ADD_DELIVERYCHARGE', {deliverycharge});

         },
         updatedate:({ commit }, {updatedate}) =>{

            commit('UPDATE_DATE', {updatedate});

         },
        totaldistance:({ commit }, {totaldistance}) =>{

            commit('ADD_TOTALDISTANCE', {totaldistance});

         },
         lat:({ commit }, {lat}) =>{

            commit('ADD_LAT', {lat});

         },
         lan:({ commit }, {lan}) =>{

            commit('ADD_LAN', {lan});

         },
         Updatemaintotal:({ commit }, {delveryamount}) =>{

            commit('UPDATE_MAINTOTAL', {delveryamount});

         },

        addProduct: ({ commit }, {product,productoption,quantity,amount}) =>{

            commit('ADD_TOCART', {product,productoption,quantity,amount});

       },
       removeProductFromCart:({ commit }, product) =>{

            commit('REMOVE_PRODUCT_FROM_CART', product);


      },
      clearCartItems:({ commit }, product) =>{

          commit('CLEAR_CART_ITEMS');
      },

      clearshippingcharge:({ commit }, product) =>{

          commit('CLEAR_SHIPPINGCHARGE');
      },



    },
    plugins:[createPersistedState()]
}










